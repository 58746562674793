import * as bootstrap from 'bootstrap';

// Styles (Bootstrap + Custom)
import './rockit.scss';

// Accept HMR
if (module && module.hot) {
    module.hot.accept();
}

window.bootstrap = bootstrap;
